<template>
  <div>
    <base-table ref="mallListTable"
                id="mallListTable"
                :columnsData="columns"
                :rowKey="rowKey"
                :tableData="tableData"
                :addHeight="-35">
      <template #toolbar>
        <div>
          <div class="flex justify-between">
            <div class="flex">
              <template v-if="isManageDrink">
                <h4 class="text-lg font-bold">小程序门店在售-好茶</h4>
                <a-button type="link" class="ml-3 p-0" @click="onChangeManage()">切换普通商品</a-button>
              </template>
              <template v-else>
                <h4 class="text-lg font-bold">小程序门店在售-普通商品</h4>
                <a-button type="link" class="ml-3 p-0"  @click="onChangeManage(1)">切换好茶</a-button>
              </template>
            </div>
            <a-button type="link" class="p-0" @click="isShowDiff=true">同步下架商品到全部网点</a-button>
          </div>
          <a-tabs type="card" :activeKey="activeKey" @change="handlerTypeChange">
            <a-tab-pane v-for="item in typeList"
                :key="item.type_id" :tab="item.type_name"></a-tab-pane>
            <div slot="tabBarExtraContent">
              <a-button type="link" class="ml-3 p-0" 
                  @click="handlerEditType">编辑分类</a-button>
            </div>
          </a-tabs>
          <div class="flex justify-between">
            <div>
              <a-tabs type="card" v-model="activeSubKey" @change="handlerTypeSubChange">
                <a-tab-pane v-for="item in typeSubList"
                    :key="item.type_id" :tab="item.type_name"></a-tab-pane>
                <div slot="tabBarExtraContent">
                  <a-button type="link" class="ml-3 p-0" 
                      @click="isShowSubType = true">编辑子分类</a-button>
                </div>
              </a-tabs>
            </div>
            <div class="flex items-center">
              <a-button v-if="!isEdit" type="primary" @click="handlerEditGoods">编辑商品</a-button>
              <template v-if="isEdit">
                <a-button type="primary" @click="isShowAddGoods = true">添加商品</a-button>
                <a-button class="ml-3" type="danger" @click="handlerEditGoodsSave">保存</a-button>
                <a-button class="ml-6" type="dashed" @click="handlerEditGoodsCancel">取消</a-button>
              </template>
            </div>
          </div>
        </div>
      </template>

      <template #cover_url="{text}">
        <img :src="text+cutQuery(50,50)" style="height:50px;" />
      </template>

      <template #operation="{record,index}">
        <a-button class="p-0" type="link"
                  @click="handlerDetail(record.goods_id)">详情</a-button>
        <a-button class="p-0 ml-3" type="link"
                  v-if="isEdit" @click="handlerDelete(index)">删除</a-button>
      </template>
    </base-table>
    <type-edit-modal v-if="isShowType" :show.sync="isShowType"
          :typeList="typeList"
          :isDrink="isManageDrink"
          @ok="initData" />
    <sub-type-edit-modal v-if="isShowSubType" :show.sync="isShowSubType"
          :typeList="typeSubList"
          :pId="activeKey.toString()"
          @ok="handlerEditSubTypeOk" />
    <add-goods-modal v-if="isShowAddGoods" :show.sync="isShowAddGoods"
          :typeId="activeSubKey"
          :selectKeys="selectKeys"
          :selectRows="tableData"
          @ok="handlerAddGoodsOk" />

    <diff-goods-modal v-if="isShowDiff" :show.sync="isShowDiff"
          :diffGoods="tableData" />
                     
  </div>
</template>

<script>
import Sortable from "sortablejs"

import TypeEditModal from "../components/type-edit-modal.vue"
import SubTypeEditModal from "../components/sub-type-edit-modal.vue"

import AddGoodsModal from "@/components/select-modal/add-goods.vue"
import DiffGoodsModal from "./components/diff-goods-modal.vue"

import pageData from "./columns"
import {filterList} from '@/utils/index'
import { cutQuery } from '@/utils/ossImg'

import {
  getTypeList,
  getSubTypeList,
  getGoodsListByTypeId,
  editTypeGoods,
  getGoodsPriceList,
} from "@/api/shop.js"

export default {
  components: {
    TypeEditModal,
    SubTypeEditModal,
    AddGoodsModal,
    DiffGoodsModal
  },
  data () {
    return {
      ...pageData,
      isShowType: false,
      isShowSubType: false,
      isEdit: false,
      isShowAddGoods: false,
      activeKey: 0,
      activeSubKey: 0,
      total: 0,
      typeList: [],
      typeSubList: [],
      tableData: [],
      selectKeys: [],

      isShowDiff: false,

      sort_table: null,

      isManageDrink: false,

      loading: false,
    }
  },
  async mounted () {
    this.initData()
  },
  methods: {
    cutQuery,
    onChangeManage(is_drink){
      is_drink = !!is_drink
      this.isManageDrink = is_drink
      
      this.handlerEditGoodsCancel()
      this.typeList = []
      this.activeKey = 0
      this.typeSubList = []
      this.activeSubKey = 0
      this.tableData = []
      this.initData()
    },
    async initData () {
      const { data, code } = await getTypeList({
        type: this.isManageDrink ? 2 : 1
      })
      if (code == 0) {
        this.typeList = data.list
        // 默认选中
        if (this.$route.query.typeId) {
          const typeArr = this.$route.query.typeId.split(',')
          this.activeKey = parseInt(typeArr[0])
          if (typeArr.length > 1) {
            this.activeSubKey = parseInt(typeArr[1])
            await this.getSubTypeList(typeArr[0])
          } else {
            this.activeSubKey = parseInt(typeArr[0])
            await this.initGoodsData()
          }
          this.isEdit = true
          this.getGoodsList(this.$route.query.goodsId)
          this.$nextTick(() => {
            this.rowDrop()
          })
        } else {
          this.activeKey = data.list[0].type_id
          this.activeSubKey = data.list[0].type_id
          this.getSubTypeList(data.list[0].type_id)
        }
      }
    },
    // 获取子分类列表
    async getSubTypeList (id) {
      const { data, code } = await getSubTypeList({ parent_type_id: id })
      if (code == 0) {
        if (data.list.length > 0) {
          this.typeSubList = data.list.map(item => {
            item.type_color = item.type_color || "#653410"
            return item
          })
          if (!this.$route.query.typeId) {
            this.activeSubKey = data.list[0].type_id
          }
        } else {
          this.typeSubList = []
          this.activeSubKey = this.activeKey
        }
        this.initGoodsData()
      }
    },
    async getGoodsList (ids) {
      getGoodsPriceList({ goods_ids: ids }).then(res => {
        if (res.code == 0) {
          this.tableData = filterList(res.data.list.concat(this.tableData), 'goods_id')
          this.selectKeys = this.tableData.map((el) => {
            return el.goods_id
          })
        }

      })
    },
    async initGoodsData () {
      const res = await getGoodsListByTypeId({ type_id: this.activeSubKey })
      this.tableData = res.data.list
      this.selectKeys = res.data.list.map((el) => {
        return el.goods_id
      })
    },
    handlerTypeChange (e) {
      this.activeKey = e
      this.getSubTypeList(e)
    },
    handlerTypeSubChange (e) {
      this.activeSubKey = e
      this.initGoodsData()
    },
    handlerEditSubTypeOk () {
      setTimeout(() => {
        this.getSubTypeList(this.activeKey)
      }, 100)
    },

    handlerEditGoods () {
      this.isEdit = true
      this.$nextTick(() => {
        this.rowDrop()
      })
    },

    handlerAddGoodsOk (goods_list) {
      if(!goods_list.length) return

      if(this.isManageDrink){
        if(goods_list.some(el=>el.goods_type!=4)){
          this.$message.info("只能选择茶饮")
          return
        }
      }else{
        if(goods_list.some(el=>el.goods_type==4)){
          this.$message.info("不可选择茶饮")
          return
        }
      }
      let ids = goods_list.map(el=>el.goods_id).join(',')
      getGoodsPriceList({ goods_ids: ids }).then(res => {
        if (res.code == 0) {
          this.tableData = filterList(res.data.list.concat(this.tableData), 'goods_id').reverse()
        }
      })
    },
    handlerEditGoodsSave () {
      if(this.loading) return

      const params = { type_id: this.activeSubKey }
      params.goods_data = JSON.stringify(
        this.tableData.map((item) => {
          return {
            goods_id: item.goods_id,
          }
        })
      )
      this.loading = true
      editTypeGoods(params).then((res) => {
        this.loading = false
        if (res.code === 0) {
          this.$message.success("编辑商品成功！")
          this.isEdit = false
          this.stopRowDrop()
          this.initGoodsData()
        }
      })
    },
    handlerEditGoodsCancel () {
      this.isEdit = false
      this.stopRowDrop()
    },
    handlerDelete (index) {
      this.tableData.splice(index, 1)
      this.selectKeys.splice(index, 1)
    },
    handlerDetail (id) {
      // this.$router.push({
      //   path: "/goods-edit",
      //   query: { type: "detail", id: id },
      // })
      const newPage = this.$router.resolve({
        path: "/goods-edit",
        query: { type: "detail", id: id },
      })
      window.open(newPage.href, "_blank")

    },
    handlerEditType () {
      this.isShowType = true
    },
    
    //行拖拽
    rowDrop () {
      if(this.sort_table){
        this.sort_table.destroy()
        this.sort_table = null
      }
      const tbody = document.querySelector(
        "#mallListTable .ant-table .ant-table-body .ant-table-tbody"
      )
      const _this = this
      this.sort_table = Sortable.create(tbody, {
        group: ".ant-table-row",
        handle: ".ant-table-row",
        onEnd ({ newIndex, oldIndex }) {
          const currRow = _this.tableData.splice(oldIndex, 1)[0]
          const currKey = _this.selectKeys.splice(oldIndex, 1)[0]
          _this.tableData.splice(newIndex, 0, currRow)
          _this.selectKeys.splice(newIndex, 0, currKey)
        },
      })
    },
    stopRowDrop(){
      if(this.sort_table){
        this.sort_table.destroy()
        this.sort_table = null
      }
    },

  },
}
</script>

<style lang="less">
.goods-search-bar {
  margin-bottom: 16px;
  padding: 15px 24px 0;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  .ant-form-item {
    margin-bottom: 5px;
  }
}
</style>