<template>
  <a-modal :visible="show"
           v-bind="$attrs"
           title="编辑分类"
           :afterClose="remove"
           width="550px"
           @cancel="remove"
           @ok="handleOk">
    <div>
      <ul class="type-table-list p-0">
        <div class="type-table-list__item">
          <div class="flex type-table-list__item-span font-bold">
            <!-- <div style="width:80px;">分类名称</div> -->
            <div class="flex-1 mr-2">分类中文名</div>
            <div class="flex-1 mr-3">分类英文名</div>
            <div style="width:80px;">操作</div>
          </div>
        </div>
        <div id="type-table-list">
          <div class="type-table-list__item"
              v-for="(item,i) in newTypeList"
              :key="item.type_id">
            <div class="flex type-table-list__item-span">
              <!-- <span style="min-width:80px;">{{item.type_name}}</span> -->
              <div class="flex-1 mr-2">
                <a-input :maxLength="8" v-model="item.type_name" />
              </div>
              <div class="flex-1 mr-3">
                <a-input :maxLength="20" v-model="item.type_name_en" />
              </div>
              <div style="width:80px;">
                <a-button type="link" class="p-0" @click="onTypeDelete(i)">删除</a-button>
              </div>
            </div>
          </div>
        </div>
      </ul>
      <a-button type="link" icon="plus" @click="onTypeAdd">新增分类</a-button>
    </div>

  </a-modal>

</template>

<script>
import _ from 'lodash'
import Sortable from 'sortablejs'

import { editType } from '@/api/shop.js'

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    typeList: {
      type: Array,
      default: () => []
    },
    isDrink: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      newTypeList: []
    }
  },
  async mounted () {
    this.newTypeList = _.cloneDeep(this.typeList)
    this.$nextTick(() => {
      this.rowDrop()
    })
  },
  methods: {
    remove () {
      this.$emit('update:show', false)
    },
    handleOk () {
      if(this.newTypeList.some(el=>{
        return !el.type_name || !el.type_name_en
      })){
        this.$message.info("请完善分类数据");
        return
      }

      const _this = this
      this.$confirm({
        title: '提示',
        content: '是否确认更新当前分类内容?',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk () {
          editType({ 
            type: _this.isDrink ? 2 : 1,
            type_data: JSON.stringify(_this.newTypeList) 
          }).then(res => {
            if (res.code === 0) {
              _this.$message.success('编辑成功！')
              _this.$emit('update:show', false)
              _this.$emit('ok', _this.newTypeList)
            }
          })
        },
        onCancel () {
          console.log('Cancel');
        },
      });

    },
    onTypeDelete (e) {
      this.newTypeList.splice(e, 1)
    },
    onTypeAdd () {
      this.newTypeList.push({
        type_id: 0,
        type_name: ''
      })
    },
    //行拖拽
    rowDrop () {
      var _this = this
      var $ul = document.getElementById('type-table-list')
      new Sortable($ul, {
        onUpdate: function (event) {
          // 更新items数组
          const item = _this.newTypeList.splice(event.oldIndex, 1)
          _this.newTypeList.splice(event.newIndex, 0, item[0])
          // 下一个tick就会走patch更新
        },
        animation: 150
      })
    }
  }
}
</script>

<style>
.type-table-list__item-span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
</style>