<template>
  <a-modal
    :visible.sync="show"
    title="相比上次同步，以下商品已经不在小程序门店页售卖"
    destroyOnClose
    :width="600"
    @cancel="$emit('update:show', false)"
    :footer="null"
  >
    <base-table
      :columnsData="columns"
      rowKey="goods_id"
      :tableData="tableData"
      :customHeight="300"
    >
      <template #cover_url="{ text }">
        <img :src="text+cutQuery(50,50)" style="height: 50px" />
      </template>
    </base-table>
    
    <div v-if="tableData && tableData.length" class="mt-5 mb-5 flex justify-center">
      <a-button class="mr-8" @click="handlerCancel">暂不下架</a-button>
      <a-button type="danger" @click="handlerOk">从全部网点下架</a-button>
    </div>
  </a-modal>
</template>

<script>
import { goodsType, formatGoodsType } from '@/utils/type.js'
import { cutQuery } from '@/utils/ossImg'
import {
  getXcxDiffGoods,
  offShelvesXcxDiffGoods
} from "@/api/shop.js"

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    diffGoods: {
      type: Array,
    },
  },
  data() {
    return {
      goodsType,
      cutQuery,
      wrapperCol: { span: 18 },
      labelCol: { span: 6 },

      columns: [
        {
          title: '图片',
          dataIndex: 'cover_url',
          align: 'center',
          width: 70,
          slots: { customRender: 'cover_url' }
        },
        {
          title: "产品属性",
          dataIndex: "goods_type",
          align: "center",
          width: 90,
          slots: {
            customRender: "type",
          },
          slotsType: "format",
          slotsFunc: (val) => formatGoodsType(val),
        },
        {
          title: "商品名称",
          dataIndex: "goods_name",
          align: "center",
        },
      ],

      tableData: [],

      loading: false,

    }
  },
  async mounted() {
    const { code, data } = await getXcxDiffGoods()
    if(code == 0){
      this.tableData = data.not_sale_goods_list
    }
  },
  methods: {
    // 保存
    handlerOk() {
      if(!this.tableData.length) return
      if(this.loading) return
      let self = this;
      this.$confirm({
        title: "确认下架这些商品？",
        async onOk() {
          if(self.loading) return
          self.loading = true
          const { code } = await offShelvesXcxDiffGoods()
          self.loading = false

          if(code == 0){
            self.$message.success("已全部下架")
            self.$emit('update:show', false)
          }
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    handlerCancel(){
      this.$emit('update:show', false)
    },

  },
}
</script>

<style>
.ant-modal-body {
  position: relative;
}
</style>